"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var onboarding_steps_1 = require("@/constants/onboarding-steps");
var getDefaultState = function () { return ({
    token: '',
    validated: false,
    companyDetails: {
        name: '',
        email: '',
        address: '',
        city: '',
        postalCode: '',
    },
    step: onboarding_steps_1.default.COMPANY_DETAILS,
}); };
var Onboarding = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
        },
        SET_TOKEN: function (state, payload) {
            state.token = payload;
        },
        SET_VALIDATED: function (state, payload) {
            state.validated = payload;
        },
        SET_ERROR_CODE: function (state, payload) {
            state.errorCode = payload;
        },
        SET_STEP: function (state, payload) {
            state.step = payload;
        },
        SET_COMPANY_DETAILS: function (state, payload) {
            state.companyDetails = payload;
        },
    },
    actions: {
        setToken: function (_a, payload) {
            var commit = _a.commit;
            commit('SET_TOKEN', payload);
        },
        validateEmail: function (_a, payload) {
            var commit = _a.commit, getters = _a.getters;
            var registrationApi = getters.getRegistrationApi;
            var requestParameters = {
                emailValidation: payload,
            };
            var apiResponse = registrationApi.postEmailValidationCollection(requestParameters);
            return apiResponse.then(function (response) {
                if (response.status === 200) {
                    return commit('SET_VALIDATED', true);
                }
                return Promise.reject(response.status);
            })
                .catch(function (error) {
                commit('SET_VALIDATED', false);
                return Promise.reject(error.response);
            });
        },
        createUser: function (_a, payload) {
            var getters = _a.getters;
            var registrationApi = getters.getRegistrationApi;
            var requestParameters = {
                onboardingUser: payload,
            };
            var apiResponse = registrationApi.postOnboardingUserCollection(requestParameters);
            return apiResponse.then(function (response) {
                if (response.status === 200) {
                    return Promise.resolve(true);
                }
                return Promise.reject(response.status);
            });
        },
        setValidated: function (_a, payload) {
            var commit = _a.commit;
            commit('SET_VALIDATED', payload);
        },
        setStep: function (_a, payload) {
            var commit = _a.commit;
            commit('SET_STEP', payload);
        },
        setCompanyDetails: function (_a, payload) {
            var commit = _a.commit, getters = _a.getters;
            var registrationApi = getters.getRegistrationApi;
            var requestParameters = {
                companyDetails: payload,
            };
            var apiResponse = registrationApi.postCompanyDetailsCollection(requestParameters);
            apiResponse.then(function (response) {
                if (response.status === 200) {
                    commit('SET_COMPANY_DETAILS', payload);
                    Promise.resolve(true);
                }
                return Promise.reject(response.status);
            })
                .catch(function (error) { return Promise.reject(error); });
        },
    },
    getters: {
        getRegistrationApi: function (state, getters, rootState, rootGetters) {
            return new api_1.RegistrationApi(rootGetters.getConfiguration);
        },
    },
};
exports.default = Onboarding;
