"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var i18n_1 = require("@/plugins/i18n");
var getDefaultState = function () { return ({
    start: true,
    badCredentials: false,
    qr: false,
    complete: false,
    qrDataUri: '',
    locale: '',
    login: {
        username: '',
        userId: null,
        roles: [],
        expire: undefined,
        recoveryCode: undefined,
    },
}); };
var Auth = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
            localStorage.setItem('store_auth', JSON.stringify(state));
        },
        start: function (state) {
            state.start = false;
            state.badCredentials = false;
        },
        fail: function (state) {
            state.badCredentials = true;
        },
        presentQr: function (state, qrCode) {
            state.start = false;
            state.badCredentials = false;
            state.qr = true;
            state.qrDataUri = qrCode;
        },
        attempt: function (state) {
            state.badCredentials = false;
        },
        complete: function (state, payload) {
            state.start = false;
            state.badCredentials = false;
            state.qr = false;
            state.complete = true;
            state.qrDataUri = '';
            state.login.username = payload.username;
            state.login.userId = payload.userId;
            state.login.expire = payload.exp;
            state.login.roles = payload.roles;
            state.login.recoveryCode = payload.recovery_codes;
            if (i18n_1.default.global.availableLocales.includes(payload.locale)) {
                i18n_1.default.global.locale = payload.locale;
                state.locale = payload.locale;
                localStorage.setItem('locale', payload.locale);
            }
        },
        initialiseStore: function (state) {
            var storeAuth = localStorage.getItem('store_auth');
            if (storeAuth) {
                var newState = JSON.parse(storeAuth);
                if (newState.login.expire > new Date().getTime() / 1000) {
                    state.start = newState.start;
                    state.badCredentials = newState.badCredentials;
                    state.complete = newState.complete;
                    state.login = newState.login;
                }
                else {
                    localStorage.setItem('store_auth', JSON.stringify(state));
                }
            }
            state.locale = localStorage.getItem('locale') || '';
        },
        UPDATE_LOCALE: function (state, payload) {
            state.locale = payload.locale;
            localStorage.setItem('locale', payload.locale);
        },
    },
    actions: {
        login: function (_a, loginObject) {
            var getters = _a.getters, state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
            var authApi = getters.getAuthenticationApi;
            var apiResponse = authApi.login({ inlineObject: loginObject });
            dispatch('resetStoreAndLocalStorage');
            return apiResponse
                .then(function (response) {
                commit('attempt');
                if (state.start
                    && response.data.message === 'use provided QR code to set up two factor authentication') {
                    commit('presentQr', response.data.qr);
                }
                else {
                    commit('complete', response.data.payload);
                    dispatch('users/getMeUser', null, { root: true });
                }
            })
                .catch(function (error) {
                if (error.response.status === 401) {
                    var data = error.response.data;
                    if (data.result === 'ok') {
                        commit('start');
                    }
                    else {
                        commit('fail');
                    }
                }
                else {
                    // eslint-disable-next-line no-console
                    console.error('System error');
                }
            });
        },
        logout: function (_a) {
            var getters = _a.getters, commit = _a.commit, dispatch = _a.dispatch;
            var authApi = getters.getAuthenticationApi;
            var apiResponse = authApi.postLogoutCollection({
                logout: { logout: '' },
            });
            return apiResponse
                .then(function (response) {
                if (response.status === 200) {
                    dispatch('resetStoreAndLocalStorage');
                }
            })
                .catch(function (error) {
                if (error.response.status === 401) {
                    commit('fail');
                }
            });
        },
        resetStoreAndLocalStorage: function (_a) {
            var dispatch = _a.dispatch;
            dispatch('resetStore', null, { root: true });
            var storageViewItems = localStorage.getItem('viewItems');
            if (storageViewItems) {
                var viewItems = JSON.parse(storageViewItems);
                viewItems.forEach(function (view) {
                    localStorage.removeItem(view.id);
                });
            }
            localStorage.removeItem('store_auth');
            localStorage.removeItem('viewItems');
        },
    },
    getters: {
        getAuthenticationApi: function (state, getters, rootState, rootGetters) {
            return new api_1.AuthenticationApi(rootGetters.getConfiguration);
        },
        isLoggedIn: function (state) {
            return !!state.login.userId;
        },
    },
};
exports.default = Auth;
