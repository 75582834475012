"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var i18n_1 = require("@/plugins/i18n");
var Locale = {
    namespaced: true,
    state: {
        isUpdated: false,
    },
    mutations: {
        setLocale: function (state, locale) {
            /* @ts-ignore:disable-next-line */
            i18n_1.default.locale = locale;
            localStorage.setItem('locale', locale);
            state.isUpdated = true;
        },
    },
    actions: {
        updateLocale: function (context, locale) {
            var commit = context.commit;
            var api = context.getters.getUserApi;
            var userLocale = { locale: locale };
            var apiResponse = api.postUserLocaleCollection({ userLocale: userLocale });
            apiResponse
                .then(function () {
                commit('setLocale', locale);
            })
                .catch(function () {
                /* istanbul ignore next */
                // eslint-disable-next-line no-console
                console.log("Could not set User's locale");
            });
        },
    },
    getters: {
        getUserApi: function (state, getters, rootState, rootGetters) {
            return new api_1.UserApi(rootGetters.getConfiguration);
        },
    },
};
exports.default = Locale;
