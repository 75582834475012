"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-e2034388"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = {
    class: "login-form",
    "data-test": "login-form"
};
var _hoisted_2 = { class: "login-form__header" };
var _hoisted_3 = { class: "login-form__title" };
var _hoisted_4 = { class: "login-form__subtitle" };
var _hoisted_5 = ["onSubmit"];
var _hoisted_6 = { class: "login-form__reset" };
var _hoisted_7 = { class: "login-form__sectiontitle" };
var _hoisted_8 = { class: "login-form__description" };
var _hoisted_9 = ["src", "alt"];
var _hoisted_10 = { class: "button__label" };
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    __name: 'LoginForm',
    setup: function (__props) {
        var _this = this;
        var store = (0, vuex_1.useStore)();
        var router = (0, vue_router_1.useRouter)();
        var credentials = (0, vue_3.reactive)({
            username: '',
            password: '',
            challenge: '',
        });
        var isLoading = (0, vue_3.ref)(false);
        var login = function () { return __awaiter(_this, void 0, void 0, function () {
            var loginObject;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isLoading.value = true;
                        loginObject = {
                            username: credentials.username,
                            password: credentials.password,
                        };
                        if (!store.state.auth.start) {
                            loginObject.challenge = credentials.challenge;
                        }
                        return [4 /*yield*/, store.dispatch('auth/login', loginObject)];
                    case 1:
                        _a.sent();
                        if (!store.state.auth.complete) return [3 /*break*/, 3];
                        return [4 /*yield*/, store.dispatch('users/getMeUser')];
                    case 2:
                        _a.sent();
                        router.push('/');
                        _a.label = 3;
                    case 3:
                        isLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _component_o_input = (0, vue_2.resolveComponent)("o-input");
            var _component_o_field = (0, vue_2.resolveComponent)("o-field");
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            var _component_router_link = (0, vue_2.resolveComponent)("router-link");
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("h1", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('login.title')), 1),
                    (0, vue_2.createElementVNode)("h2", _hoisted_4, (0, vue_2.toDisplayString)(_ctx.$t('login.subtitle')), 1)
                ]),
                (0, vue_2.createElementVNode)("form", {
                    onSubmit: (0, vue_2.withModifiers)(login, ["prevent"])
                }, [
                    (0, vue_2.createVNode)(_component_o_field, {
                        label: _ctx.$t('general.username'),
                        "label-for": "username",
                        variant: !(0, vue_2.unref)(store).state.auth.start
                            ? 'success'
                            : (0, vue_2.unref)(store).state.auth.badCredentials && (0, vue_2.unref)(store).state.auth.start
                                ? 'danger'
                                : '',
                        "label-class": "is-sr-only"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_o_input, {
                                id: "username",
                                placeholder: _ctx.$t('login.username_placeholder'),
                                modelValue: credentials.username,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((credentials.username) = $event); }),
                                type: "email",
                                readonly: !(0, vue_2.unref)(store).state.auth.start
                            }, null, 8, ["placeholder", "modelValue", "readonly"])
                        ]; }),
                        _: 1
                    }, 8, ["label", "variant"]),
                    (0, vue_2.createVNode)(_component_o_field, {
                        label: _ctx.$t('general.password'),
                        "label-for": "password",
                        variant: !(0, vue_2.unref)(store).state.auth.start
                            ? 'success'
                            : (0, vue_2.unref)(store).state.auth.badCredentials && (0, vue_2.unref)(store).state.auth.start
                                ? 'danger'
                                : '',
                        message: (0, vue_2.unref)(store).state.auth.badCredentials && (0, vue_2.unref)(store).state.auth.start
                            ? 'Incorrect username / password combination'
                            : null,
                        "label-class": "is-sr-only"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_o_input, {
                                id: "password",
                                type: "password",
                                placeholder: _ctx.$t('login.password_placeholder'),
                                modelValue: credentials.password,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((credentials.password) = $event); }),
                                readonly: !(0, vue_2.unref)(store).state.auth.start
                            }, null, 8, ["placeholder", "modelValue", "readonly"])
                        ]; }),
                        _: 1
                    }, 8, ["label", "variant", "message"]),
                    (0, vue_2.createElementVNode)("p", _hoisted_6, [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('login.forgot_password')) + " ", 1),
                        (0, vue_2.createVNode)(_component_router_link, {
                            to: {
                                path: '/forgot-password',
                            },
                            class: "login-form__back-link"
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('login.reset_password')) + " ", 1),
                                (0, vue_2.createVNode)(_component_o_icon, {
                                    pack: "pam",
                                    icon: "arrow_right"
                                })
                            ]; }),
                            _: 1
                        })
                    ]),
                    (0, vue_2.createElementVNode)("h3", _hoisted_7, (0, vue_2.toDisplayString)(_ctx.$t('login.setup_mfa')), 1),
                    (0, vue_2.createElementVNode)("p", _hoisted_8, (0, vue_2.toDisplayString)(_ctx.$t('login.mfa_instructions')), 1),
                    ((0, vue_2.unref)(store).state.auth.qr)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_field, { key: 0 }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createElementVNode)("img", {
                                    src: (0, vue_2.unref)(store).state.auth.qrDataUri,
                                    alt: _ctx.$t('login.qr_img_alt')
                                }, null, 8, _hoisted_9)
                            ]; }),
                            _: 1
                        }))
                        : (0, vue_2.createCommentVNode)("", true),
                    (!(0, vue_2.unref)(store).state.auth.start)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_field, {
                            key: 1,
                            label: _ctx.$t('login.challenge'),
                            "label-for": "challenge",
                            variant: (0, vue_2.unref)(store).state.auth.complete ? 'success' : (0, vue_2.unref)(store).state.auth.badCredentials ? 'danger' : '',
                            message: (0, vue_2.unref)(store).state.auth.badCredentials ? 'Incorrect code' : null,
                            "label-class": "is-sr-only"
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_o_input, {
                                    id: "challenge",
                                    type: "text",
                                    placeholder: _ctx.$t('login.challenge_placeholder'),
                                    maxlength: "6",
                                    modelValue: credentials.challenge,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((credentials.challenge) = $event); }),
                                    readonly: (0, vue_2.unref)(store).state.auth.complete
                                }, null, 8, ["placeholder", "modelValue", "readonly"])
                            ]; }),
                            _: 1
                        }, 8, ["label", "variant", "message"]))
                        : (0, vue_2.createCommentVNode)("", true),
                    (0, vue_2.createVNode)(_component_o_button, {
                        variant: "active",
                        "native-type": "submit",
                        class: (0, vue_2.normalizeClass)(["button--shadowed", { 'button--loading': isLoading.value }]),
                        id: "login"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createElementVNode)("span", _hoisted_10, (0, vue_2.toDisplayString)(_ctx.$t('login.button')), 1)
                        ]; }),
                        _: 1
                    }, 8, ["class"])
                ], 40, _hoisted_5)
            ]));
        };
    }
});
