"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-44c8153f"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = {
    class: "mail-table-actions",
    "data-test": "mails-table-selection"
};
var vue_3 = require("vue");
var useMailBatch_1 = require("@/composables/useMailBatch");
var vuex_1 = require("vuex");
var vue_i18n_1 = require("vue-i18n");
var mail_type_1 = require("@/constants/mail-type");
exports.default = (0, vue_1.defineComponent)({
    __name: 'MailTableActions',
    emits: ['processed'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var store = (0, vuex_1.useStore)();
        var $helpers = (0, vue_3.inject)('$helpers');
        var t = (0, vue_i18n_1.useI18n)().t;
        var processSelectedMails = (0, useMailBatch_1.default)({
            $helpers: $helpers,
            $i18n: { t: t },
            $emit: emit,
        }).processSelectedMails;
        var verifyWithCategoryBulk = (0, vue_3.computed)(function () { return store.getters['views/getVerifyItemsWithCategoryFromView']('bulk'); });
        var isBulkAllowed = (0, vue_3.computed)(function () { return store.getters['views/getBulkOptionFromView']; });
        return function (_ctx, _cache) {
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (isBulkAllowed.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_button, {
                        key: 0,
                        class: "mail-table-actions__button",
                        onClick: _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.unref)(processSelectedMails)((0, vue_2.unref)(mail_type_1.default).CONFIRM)); }),
                        "icon-pack": "giraf",
                        "icon-left": "check"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('table.mails.confirm_selected')), 1)
                        ]; }),
                        _: 1
                    }))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createVNode)(_component_o_button, {
                    class: "mail-table-actions__button",
                    onClick: _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.unref)(processSelectedMails)((0, vue_2.unref)(mail_type_1.default).REJECT)); }),
                    "icon-pack": "giraf",
                    "icon-left": "alert"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('table.mails.reject_selected')), 1)
                    ]; }),
                    _: 1
                }),
                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(verifyWithCategoryBulk.value, function (correction) {
                    return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_button, {
                        key: correction.label,
                        class: "mail-table-actions__button",
                        onClick: function ($event) { return ((0, vue_2.unref)(processSelectedMails)((0, vue_2.unref)(mail_type_1.default).REPROCESS, correction)); },
                        "icon-pack": "giraf",
                        "icon-left": correction.label === 'match' ? 'check' : 'sync'
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('table.mails.' + correction.label.toLowerCase())), 1)
                        ]; }),
                        _: 2
                    }, 1032, ["onClick", "icon-left"]));
                }), 128))
            ]));
        };
    }
});
