"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./assets/css/app.scss");
var vue_1 = require("vue");
var i18n_1 = require("@/plugins/i18n");
var store_1 = require("@/store");
var oruga_next_1 = require("@oruga-ui/oruga-next");
var oruga_ui_1 = require("@/plugins/oruga-ui");
require("@/plugins/axios");
require("@/plugins/vee-validate");
var event_bus_1 = require("@/plugins/event-bus");
require("@/plugins/msw");
var helpers_1 = require("@/services/helpers");
var router_1 = require("./router");
var App_vue_1 = require("./App.vue");
var app = (0, vue_1.createApp)({
    extends: App_vue_1.default,
    beforeCreate: function () {
        this.$store.commit('auth/initialiseStore');
    },
});
app.use(router_1.default);
app.use(i18n_1.default);
app.use(store_1.default);
app.use(event_bus_1.default);
app.use(oruga_next_1.default, oruga_ui_1.default);
app.use(helpers_1.default, { oruga: oruga_next_1.default, $t: i18n_1.default.global.t });
app.config.globalProperties.$tooltipDelay = 1000;
app.mount('#app');
