"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var store_1 = require("@/store");
var mail_type_1 = require("@/constants/mail-type");
var mail_send_1 = require("@/constants/mail-send");
function useMailBatch(root) {
    var _this = this;
    var $helpers = root.$helpers, $i18n = root.$i18n, $emit = root.$emit;
    var selectedMails = (0, vue_1.computed)(function () { return store_1.default.state.mails.selectedMails; });
    var setMailsSendingInProgress = function (value, mailType) { return __awaiter(_this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, store_1.default.commit('mails/SET_SELECTED_SENDING', value)];
                case 1:
                    _a.sent();
                    if (!mailType) return [3 /*break*/, 3];
                    return [4 /*yield*/, store_1.default.commit('mails/SET_SELECTED_SENDTYPE', mailType)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, store_1.default.commit('setPageLoading', value)];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleFailure = function (mailType, amount) {
        if (amount === mail_send_1.default.BULK) {
            store_1.default.commit('mails/SET_BULK_SENDING_ERROR', true);
            $helpers.showNotificationMessage({
                message: "".concat($i18n.t("table.mails.bulk_mails_error_".concat(mailType)), "<br />").concat($i18n.t('general.try_again')),
                variant: 'error',
            });
        }
        else if (amount === mail_send_1.default.SINGLE) {
            $helpers.showNotificationMessage({
                message: "".concat($i18n.t("table.mails.mail_error_".concat(mailType))),
                variant: 'warning',
            });
        }
        setMailsSendingInProgress(false);
    };
    var onMailsSendingFinished = function (mailType) {
        setMailsSendingInProgress(false);
        $helpers.showNotificationMessage({
            message: $i18n.t("table.mails.bulk_mails_sent_successfully_".concat(mailType)),
        });
    };
    var handlePromiseAllSettled = function (promises, mailType) { return __awaiter(_this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.allSettled(promises).then(function (results) {
                        if (results.every(function (result) { return result.status === 'rejected'; })) {
                            return handleFailure(mailType, mail_send_1.default.BULK);
                        }
                        if (results.some(function (result) { return result.status === 'rejected'; })) {
                            return handleFailure(mailType, mail_send_1.default.SINGLE);
                        }
                        return onMailsSendingFinished(mailType);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var sendBulkConfirmMail = function (selectedMail) { return __awaiter(_this, void 0, Promise, function () {
        var mail, mailResponse, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, store_1.default.dispatch('mails/loadMail', selectedMail.id)];
                case 1:
                    mail = _a.sent();
                    mailResponse = store_1.default.getters['views/getCurrentViewMailResponse'](mail);
                    return [4 /*yield*/, store_1.default.dispatch('actionMessage/confirmAction', {
                            mailId: selectedMail.id,
                            field: store_1.default.state.views.view.confirm.field,
                            value: mailResponse,
                            isBulkAction: true,
                        })];
                case 2:
                    _a.sent();
                    store_1.default.commit('mails/INCREMENT_SELECTED_COUNT');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    handleFailure(mail_type_1.default.CONFIRM, mail_send_1.default.BULK);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var sendBulkConfirmMails = function () { return __awaiter(_this, void 0, Promise, function () {
        var confirmActionPromises, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    store_1.default.commit('mails/SET_OVERVIEW_LOADED', false);
                    confirmActionPromises = selectedMails.value.map(sendBulkConfirmMail);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, handlePromiseAllSettled(confirmActionPromises, mail_type_1.default.CONFIRM)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    handleFailure(mail_type_1.default.CONFIRM, mail_send_1.default.BULK);
                    return [3 /*break*/, 5];
                case 4:
                    store_1.default.commit('mails/RESET_SELECTED_MAILS');
                    store_1.default.commit('mails/SET_OVERVIEW_LOADED', true);
                    $emit('processed');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var sendBulkRejectMail = function (selectedMail) { return __awaiter(_this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, store_1.default.dispatch('correction/rejectAction', {
                        mailId: selectedMail.id,
                        field: 'skip_reply',
                        value: 'true',
                        isBulkAction: true,
                    })];
                case 1:
                    _a.sent();
                    store_1.default.commit('mails/INCREMENT_SELECTED_COUNT');
                    return [2 /*return*/];
            }
        });
    }); };
    var sendBulkRejectMails = function () { return __awaiter(_this, void 0, Promise, function () {
        var rejectActionPromises, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    store_1.default.commit('mails/SET_OVERVIEW_LOADED', false);
                    rejectActionPromises = selectedMails.value.map(sendBulkRejectMail);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, handlePromiseAllSettled(rejectActionPromises, mail_type_1.default.REJECT)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_3 = _a.sent();
                    handleFailure(mail_type_1.default.REJECT, mail_send_1.default.BULK);
                    return [3 /*break*/, 5];
                case 4:
                    store_1.default.commit('mails/RESET_SELECTED_MAILS');
                    store_1.default.commit('mails/SET_OVERVIEW_LOADED', true);
                    $emit('processed');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var sendBulkReprocessMail = function (selectedMail, reprocessor) { return __awaiter(_this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, store_1.default.dispatch('correction/correctType', {
                        mailId: selectedMail.id,
                        type: reprocessor.correctiontype,
                        oldValue: '',
                        newValue: reprocessor.value,
                        isBulkAction: true,
                    })];
                case 1:
                    _a.sent();
                    store_1.default.commit('mails/INCREMENT_SELECTED_COUNT');
                    return [2 /*return*/];
            }
        });
    }); };
    var sendBulkReprocessMails = function (reprocessor) { return __awaiter(_this, void 0, Promise, function () {
        var correctTypePromises, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    store_1.default.commit('mails/SET_OVERVIEW_LOADED', false);
                    correctTypePromises = selectedMails.value.map(function (selectedMail) { return sendBulkReprocessMail(selectedMail, reprocessor); });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, handlePromiseAllSettled(correctTypePromises, mail_type_1.default.REPROCESS)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_4 = _a.sent();
                    handleFailure(mail_type_1.default.REPROCESS, mail_send_1.default.BULK);
                    return [3 /*break*/, 5];
                case 4:
                    store_1.default.commit('mails/RESET_SELECTED_MAILS');
                    store_1.default.commit('mails/SET_OVERVIEW_LOADED', true);
                    $emit('processed');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var delay = function (ms) { return __awaiter(_this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    setTimeout(resolve, ms);
                })];
        });
    }); };
    // eslint-disable-next-line vue/max-len
    var processSelectedMails = function (mailType, reprocessorArg) {
        if (reprocessorArg === void 0) { reprocessorArg = null; }
        return __awaiter(_this, void 0, Promise, function () {
            var mailProcessingFunctions, mailProcessingFunction;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        store_1.default.commit('mails/RESET_BULK_STATES');
                        setMailsSendingInProgress(true, mailType);
                        mailProcessingFunctions = (_a = {},
                            _a[mail_type_1.default.CONFIRM] = sendBulkConfirmMails,
                            _a[mail_type_1.default.REJECT] = sendBulkRejectMails,
                            _a[mail_type_1.default.REPROCESS] = function (reprocessor) { return sendBulkReprocessMails(reprocessor); },
                            _a);
                        mailProcessingFunction = mailProcessingFunctions[mailType];
                        if (!mailProcessingFunction) return [3 /*break*/, 2];
                        return [4 /*yield*/, mailProcessingFunction(reprocessorArg)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, delay(2000)];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return { selectedMails: selectedMails, processSelectedMails: processSelectedMails };
}
exports.default = useMailBatch;
