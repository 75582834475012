"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var textEditor_vue_1 = require("@/components/mail/textEditor.vue");
var useDate_1 = require("@/composables/useDate");
exports.default = (0, vue_1.defineComponent)({
    name: 'MailDetail',
    components: { TextEditor: textEditor_vue_1.default },
    props: {
        mailId: {
            type: String,
            default: '',
        },
        layout: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        var formatDate = (0, useDate_1.default)().formatDate;
        return {
            verifyValues: {},
            isFieldChanged: false,
            isEditable: false,
            initialResponse: '',
            isLoadingCorrectType: false,
            isLoadingConfirm: false,
            isLoadingCancel: false,
            showViewMoreButton: false,
            formatDate: formatDate,
        };
    },
    inject: ['$helpers'],
    computed: __assign(__assign({}, (0, vuex_1.mapState)({
        mail: function (state) { return state.mails.mail; },
        views: function (state) { return state.views; },
    })), { selectedCriteria: function () {
            var criteria = JSON.parse(this.mail['processor_data.candidate_matching_criteria']);
            Object.keys(criteria).forEach(function (key) {
                criteria[key] = {
                    id: key,
                    checked: criteria[key],
                };
            });
            return criteria;
        }, mailResponse: {
            get: function () {
                return this.$store.getters['views/getCurrentViewMailResponse'](this.mail);
            },
            set: function (value) {
                var contentFieldResponse = this.getContentField(this.layout.sections.response);
                if (contentFieldResponse !== undefined) {
                    this.$store.commit('mails/SET_MAIL_REPLY', {
                        contentFieldResponse: contentFieldResponse,
                        value: value,
                    });
                }
            },
        }, items: function () {
            return this.layout.sections.header.filter(function (item) { return item.component === 'LabelValue' || item.component === 'FieldName'; });
        }, requirements: function () {
            var requirements = JSON.parse(this.mail['processor_data.property_requirements']);
            if (requirements.occupancy_requirements) {
                requirements.occupancy_requirements = requirements.occupancy_requirements.join(', ');
            }
            return requirements;
        }, gptExlanationIsJson: function () {
            var str = this.mail['processor_data.gpt_explanation'];
            try {
                JSON.parse(str);
            }
            catch (e) {
                return false;
            }
            return true;
        }, gptExplanations: function () {
            var explanations = JSON.parse(this.mail['processor_data.gpt_explanation']);
            return Object.fromEntries(Object.entries(explanations).filter(function (_a) {
                var value = _a[1];
                return value;
            }));
        }, initialVerifyValues: function () {
            return this.views.view.verify.reduce(function (obj, item) {
                var _a;
                return Object.assign(obj, (_a = {},
                    _a[item.field] = item.options ? item.options[0].value : '',
                    _a));
            }, {});
        }, receivedDate: function () {
            var dateComponent = this.layout.sections.header.filter(function (item) { return item.component === 'Date'; });
            return this.mail[dateComponent[0].field];
        } }),
    created: function () {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var error_1, messageKey;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.$store.dispatch('mails/loadMail', this.mailId)];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        messageKey = ((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.status) === 404
                            ? 'table.mails.load_mail_error_404'
                            : 'table.mails.load_mail_error';
                        this.$helpers.showNotificationMessage({
                            message: this.$t(messageKey),
                            variant: 'error',
                        });
                        this.$emit('close');
                        return [2 /*return*/];
                    case 3:
                        this.verifyValues = JSON.parse(JSON.stringify(this.initialVerifyValues));
                        this.countLines();
                        return [2 /*return*/];
                }
            });
        });
    },
    watch: {
        verifyValues: {
            deep: true,
            handler: function () {
                this.isFieldChanged = JSON.stringify(this.verifyValues)
                    !== JSON.stringify(this.initialVerifyValues);
            },
        },
    },
    methods: {
        getMatchingCriteria: function () {
            return JSON.parse(this.mail['processor_data.candidate_matching_criteria']);
        },
        editResponseToggle: function () {
            if (!this.isEditable) {
                this.initialResponse = this.mailResponse;
            }
            this.isEditable = !this.isEditable;
        },
        restoreMailResponse: function () {
            this.mailResponse = this.initialResponse;
            this.isEditable = false;
        },
        getVerifiedFieldLabel: function (item) {
            var _this = this;
            var _a;
            var options = this.getVerifyFieldOptions(item);
            if (options === undefined) {
                return '';
            }
            var field = (_a = options.find(function (verifyFieldOptions) { return verifyFieldOptions.value === _this.verifyValues[item.field]; })) === null || _a === void 0 ? void 0 : _a.value;
            if (field === undefined) {
                return '';
            }
            return this.$t("enum.corrections.".concat(field === null || field === void 0 ? void 0 : field.toLowerCase()));
        },
        /**
         * Check if field is allowed to be verified found in configured view.
         */
        isVerifyField: function (item) {
            var verifyField = this.views.view.verify.find(function (verifyItem) { return verifyItem.field === item.field; });
            return verifyField !== undefined;
        },
        /**
         * Get options of verify field found in configured view.
         */
        getVerifyFieldOptions: function (item) {
            var verifyField = this.views.view.verify.find(function (verifyItem) { return verifyItem.field === (item === null || item === void 0 ? void 0 : item.field); });
            if (!verifyField) {
                return undefined;
            }
            return verifyField.options;
        },
        /**
         * Get correctiontype of verify field found in configured view.
         */
        getVerifyFieldCorrectionType: function (field) {
            var verifyField = this.views.view.verify.find(function (verifyItem) { return verifyItem.field === field; });
            return verifyField.correctiontype;
        },
        getTitle: function (layout) {
            var titleObject = layout.find(function (item) { return item.component === 'Title'; });
            if (!titleObject) {
                return undefined;
            }
            if (!titleObject.properties.translation_key) {
                return titleObject.properties.title;
            }
            return this.$t("detail.title.".concat(titleObject.properties.translation_key));
        },
        getContentField: function (layout) {
            var contentObject = layout.find(function (item) { return item.properties.content; });
            return contentObject === null || contentObject === void 0 ? void 0 : contentObject.field;
        },
        countLines: function () {
            var container = document.getElementById('inner-block__container');
            if (!container) {
                return;
            }
            var height = container.offsetHeight;
            var lineHeight = getComputedStyle(container).lineHeight;
            var lines = height / parseInt(lineHeight, 10);
            if (lines >= 20) {
                this.showViewMoreButton = true;
            }
        },
        viewMore: function () {
            this.showViewMoreButton = false;
        },
        confirmAction: function (mailId, field, value) {
            var _a;
            return __awaiter(this, void 0, Promise, function () {
                var response, objectSelectedCriteria_1, error_2, messageKey;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (this.isFieldChanged || this.isEditable) {
                                return [2 /*return*/];
                            }
                            this.isLoadingConfirm = true;
                            this.$store.commit('mails/UNSET_MAIL', mailId);
                            switch (value) {
                                case 'mail.text':
                                    response = this.mailResponse;
                                    break;
                                case 'processor_data.candidate_matching_criteria': {
                                    objectSelectedCriteria_1 = {};
                                    Object.keys(this.selectedCriteria).forEach(function (key) {
                                        objectSelectedCriteria_1[key] = _this.selectedCriteria[key].checked;
                                    });
                                    response = JSON.stringify(objectSelectedCriteria_1);
                                    break;
                                }
                                default:
                                    response = value;
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, this.$store.dispatch('actionMessage/confirmAction', {
                                    mailId: mailId,
                                    field: field,
                                    value: response,
                                })];
                        case 2:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            error_2 = _b.sent();
                            messageKey = ((_a = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _a === void 0 ? void 0 : _a.status) === 404
                                ? 'detail.response_sent.error_404'
                                : 'detail.response_sent.error';
                            this.$helpers.showNotificationMessage({
                                message: this.$t(messageKey),
                                variant: 'error',
                            });
                            return [3 /*break*/, 5];
                        case 4:
                            this.isLoadingConfirm = false;
                            this.$emit('close');
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        cancelAction: function (mailId) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.isLoadingCancel = true;
                            return [4 /*yield*/, this.$store.dispatch('correction/rejectAction', {
                                    mailId: mailId,
                                    field: 'skip_reply',
                                    value: 'true',
                                })];
                        case 1:
                            _a.sent();
                            this.isLoadingCancel = false;
                            this.$emit('close');
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Submit corrected fields.
         */
        correct: function (id) {
            return __awaiter(this, void 0, Promise, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.isFieldChanged) {
                                return [2 /*return*/];
                            }
                            this.isLoadingCorrectType = true;
                            return [4 /*yield*/, Promise.all(Object.entries(this.verifyValues).map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (this.initialVerifyValues[item[0]] === item[1]) {
                                                    return [2 /*return*/];
                                                }
                                                return [4 /*yield*/, this.$store.dispatch('correction/correctType', {
                                                        mailId: id,
                                                        type: this.getVerifyFieldCorrectionType(item[0]),
                                                        oldValue: this.initialVerifyValues[item[0]],
                                                        newValue: item[1],
                                                    })];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }))];
                        case 1:
                            _a.sent();
                            this.isLoadingCorrectType = false;
                            this.$store.commit('mails/UNSET_MAIL', id);
                            this.$emit('close');
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
